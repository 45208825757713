body {
  background-color: #F1E6CF;
}

.prompt-input {
  height: 40px;
  width: 100px;
  padding: 0px 7px;
  border: 2px solid #386256;
}

.submit-button {
  white-space: normal;
  padding: 7px;
  color: #386256;
  background-color: #A4B88E;
  border-color: #386256;
  height: 45px;
  font-weight: bold; 
  margin-left: 15px;
}

.submit-button:disabled {
  color: #A6A6A6;
  background-color: #E5E5E5;
}

.customShadow {
  filter: brightness(0%) blur(0.2px);
}

.hide {
  display: none;
}

progress::-moz-progress-bar { background: #386256; }
progress::-webkit-progress-value { background: #386256; }
progress { color: #386256; }